import { findIndex, map, reject } from 'lodash';

import {
  ACTION_CREATE_TOPIC,
  ACTION_DELETE_TOPIC,
  ACTION_GET_ALL_TOPICS,
  ACTION_GET_TOPIC,
  ACTION_GET_TOPICS,
  ACTION_UPDATE_TOPIC,
} from './actionTypes';

const initialState = {
  topics: {
    items: [],
    totalCount: 0,
  },
};

/**
 * @typedef {object} KeywordLanguage
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {object} Keyword
 * @property {string} id
 * @property {string} name
 * @property {KeywordLanguage} language
 * @property {string} subtopicId
 */

/**
 * @typedef {object} Subtopic
 * @property {string} id
 * @property {string} name
 * @property {string} topicId
 * @property {Keyword[]} keywords
 */

/**
 * @typedef {object} Topic
 * @property {string} id
 * @property {string} name
 * @property {Array<Subtopic>} subtopics
 */

/**
 * Returns all topics
 * @param {object} state
 * @returns {Topic[]}
 */
export const topicsSelector = (state) => state.topics.topics.items;

/**
 * Returns the total count of topics
 * @param {object} state
 * @returns {number}
 */
export const topicsCountSelector = (state) => state.topics.topics.totalCount;

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_GET_TOPICS: {
      return {
        ...state,
        topics: {
          items: payload ? payload.items : [],
          totalCount: payload ? payload.totalCount : 0,
        },
      };
    }
    case ACTION_GET_ALL_TOPICS: {
      return {
        ...state,
        topics: {
          items: payload,
          totalCount: payload.length,
        },
      };
    }
    case ACTION_GET_TOPIC: {
      const { items } = state.topics;
      let index = findIndex(items, { id: payload.id });
      index = index >= 0 ? index : items.length;
      const replaced = [...items];
      replaced[index] = payload;
      return {
        ...state,
        topics: {
          items: replaced,
          totalCount: state.topics.totalCount,
        },
      };
    }
    case ACTION_CREATE_TOPIC: {
      return {
        ...state,
        topics: {
          items: [...state.topics.items, payload],
          totalCount: state.topics.totalCount + 1,
        },
      };
    }
    case ACTION_UPDATE_TOPIC: {
      return {
        ...state,
        topics: {
          items: map(state.topics.items, (item) => {
            if (item.id !== payload.id) {
              return item;
            }
            return payload;
          }),
          totalCount: state.topics.totalCount,
        },
      };
    }
    case ACTION_DELETE_TOPIC: {
      return {
        ...state,
        topics: {
          items: reject(state.topics.items, { id: payload.id }),
          totalCount: state.topics.totalCount - 1,
        },
      };
    }
    default:
      return state;
  }
}
