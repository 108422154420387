import {
  ACTION_AUTH_LOGIN_SUCCESS,
  ACTION_AUTH_LOGOUT,
  ACTION_AUTH_REFRESH_TOKEN_SUCCESS,
} from './actionTypes';

const initialState = {
  accessToken: null,
  refreshToken: null,
};

/**
 * Returns the current access token
 * @param {object} state
 * @returns {string}
 */
export const accessTokenSelector = (state) => state.auth.accessToken;

/**
 * Returns the current refresh token
 * @param {object} state
 * @returns {string}
 */
export const refreshTokenSelector = (state) => state.auth.refreshToken;

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_AUTH_LOGIN_SUCCESS:
    case ACTION_AUTH_REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case ACTION_AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
