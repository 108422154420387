export default {
  // Badge
  badge: {
    warning: 'text-orange-700 bg-orange-100 dark:text-white dark:bg-orange-600',
    primary: 'text-orange-700 bg-orange-100 dark:text-white dark:bg-orange-600',
  },
  // Textarea
  textarea: {
    active:
      'focus:border-orange-400 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:shadow-outline-gray focus:shadow-outline-orange',
  },
  // Select
  select: {
    active:
      'focus:border-orange-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-orange dark:focus:shadow-outline-gray dark:focus:border-gray-600',
  },
  // Input
  input: {
    active:
      'focus:border-orange-400 dark:border-gray-600 focus:shadow-outline-orange dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700',
    radio:
      'text-orange-600 form-radio focus:border-orange-400 focus:outline-none focus:shadow-outline-orange dark:focus:shadow-outline-gray',
    checkbox:
      'text-orange-600 form-checkbox focus:border-orange-400 focus:outline-none focus:shadow-outline-orange dark:focus:shadow-outline-gray',
  },
  // Button
  button: {
    primary: {
      base: 'text-white bg-orange-600 border border-transparent',
      active:
        'active:bg-orange-600 hover:bg-orange-700 focus:shadow-outline-orange',
    },
  },
};
