import { findIndex, map, reject } from 'lodash';

import {
  ACTION_CREATE_CUSTOMER,
  ACTION_DELETE_CUSTOMER,
  ACTION_GET_ALL_CUSTOMERS,
  ACTION_GET_CUSTOMER,
  ACTION_GET_CUSTOMERS,
  ACTION_UPDATE_CUSTOMER,
} from './actionTypes';

const initalState = {
  customers: {
    items: [],
    totalCount: 0,
  },
};

/**
 * @typedef {object} Customer
 * @property {string} id
 * @property {string} name
 * @property {string} companyNumber
 * @property {string} address
 * @property {string} contactPerson
 * @property {string} email
 * @property {string} phone
 */

/**
 * Returns all customers
 * @param {object} state
 * @returns {Customer[]}
 */
export const customersSelector = (state) => state.customers.customers.items;
/**
 * Returns count of all customers
 * @param {object} state user
 * @returns {number}
 */
export const customersCountSelector = (state) =>
  state.customers.customers.totalCount;

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case ACTION_GET_CUSTOMERS: {
      return {
        ...state,
        customers: {
          items: payload ? payload.items : [],
          totalCount: payload ? payload.totalCount : 0,
        },
      };
    }
    case ACTION_GET_ALL_CUSTOMERS: {
      return {
        ...state,
        customers: {
          items: payload,
          totalCount: payload.length,
        },
      };
    }
    case ACTION_GET_CUSTOMER: {
      const { items } = state.customers;
      let index = findIndex(items, { id: payload.id });
      index = index >= 0 ? index : items.length;
      const replaced = [...items];
      replaced[index] = payload;
      return {
        ...state,
        customers: {
          items: replaced,
          totalCount: state.customers.totalCount,
        },
      };
    }
    case ACTION_CREATE_CUSTOMER: {
      return {
        ...state,
        customers: {
          items: [...state.customers.items, payload],
          totalCount: state.customers.totalCount + 1,
        },
      };
    }
    case ACTION_UPDATE_CUSTOMER: {
      return {
        ...state,
        customers: {
          items: map(state.customers.items, (item) => {
            if (item.id !== payload.id) {
              return item;
            }
            return payload;
          }),
          totalCount: state.customers.totalCount,
        },
      };
    }
    case ACTION_DELETE_CUSTOMER: {
      return {
        ...state,
        customers: {
          items: reject(state.customers.items, { id: payload.id }),
          totalCount: state.customers.totalCount - 1,
        },
      };
    }
    default:
      return state;
  }
}
