import React from 'react';
import { useTranslation } from 'react-i18next';

function ThemedSuspense() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center w-screen h-screen p-6 text-xl font-bold text-x-gray dark:text-x-orange dark:bg-gray-900">
      {t('Loading')}
    </div>
  );
}

export default ThemedSuspense;
