import '@pathofdev/react-tag-input/build/index.css';

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import Alert from './components/Alert';
import { accessTokenSelector } from './stores/auth/reducer';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const AuthenticatedRoutes = [
  <Route key="app" component={Layout} path="/app" />,
];

const UnauthenticatedRoutes = [
  <Route key="lg" component={Login} path="/login" />,
  <Route key="fp" component={ForgotPassword} path="/forgot-password" />,
  <Route key="rp" component={ResetPassword} path="/reset-password" />,
];

Chart.plugins.unregister(ChartDataLabels);

function App() {
  const accessToken = useSelector(accessTokenSelector);

  return (
    <Router>
      <AccessibleNavigationAnnouncer />
      <Switch>
        {!accessToken && UnauthenticatedRoutes}
        {accessToken && AuthenticatedRoutes}
        <Redirect to={accessToken ? '/app' : '/login'} />
      </Switch>
      <Alert />
    </Router>
  );
}

export default App;
