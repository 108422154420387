import { map, reject } from 'lodash';

import {
  ACTION_CREATE_USER,
  ACTION_DELETE_USER,
  ACTION_FETCH_CURRENT_USER,
  ACTION_FETCH_USERS,
  ACTION_UPDATE_USER,
} from './actionTypes';

const initialState = {
  users: {
    items: [],
    totalCount: 0,
  },
  current: {},
};

/**
 * @typedef {object} User
 * @property {string} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {number} age
 * @property {string} role
 * @property {boolean} isActive
 * @property {string} fullName
 * @property {string} customerId
 */

/**
 * Returns the current user
 * @param {object} state
 * @returns {User}
 */
export const currentUserSelector = (state) => state.users.current;
/**
 * Returns all users
 * @param {object} state
 * @returns {User[]}
 */
export const usersSelector = (state) => state.users.users.items;
/**
 * Returns count of all users
 * @param {object} state user
 * @returns {number}
 */
export const usersCountSelector = (state) => state.users.users.totalCount;

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_FETCH_CURRENT_USER: {
      return {
        ...state,
        current: payload,
      };
    }
    case ACTION_FETCH_USERS: {
      return {
        ...state,
        users: payload,
      };
    }
    case ACTION_CREATE_USER: {
      return {
        ...state,
        users: {
          items: [...state.users.items, payload],
          totalCount: state.users.totalCount + 1,
        },
      };
    }
    case ACTION_UPDATE_USER: {
      return {
        ...state,
        users: {
          items: map(state.users.items, (item) => {
            if (item.id !== payload.id) {
              return item;
            }
            return payload;
          }),
          totalCount: state.users.totalCount,
        },
      };
    }
    case ACTION_DELETE_USER: {
      return {
        ...state,
        users: {
          items: reject(state.users.items, { id: payload.id }),
          totalCount: state.users.totalCount - 1,
        },
      };
    }
    default:
      return state;
  }
}
