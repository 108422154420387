import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import alert from './alert/reducer';
import auth from './auth/reducer';
import customers from './customers/reducer';
import nomenclatures from './nomenclatures/reducer';
import notifications from './notifications/reducer';
import roles from './roles/reducer';
import topics from './topics/reducer';
import users from './users/reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['alert'],
};

const rootReducer = combineReducers({
  auth,
  users,
  roles,
  alert,
  customers,
  topics,
  nomenclatures,
  notifications,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
