import 'moment/locale/bg';

import bg from 'date-fns/locale/bg';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';

import resources from './assets/translations';

// the locale you want
registerLocale('bg', bg);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'bg',
    resources,
    keySeparator: false,
  })
  .then(() => {
    moment.locale(i18n.language);
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
