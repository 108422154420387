import './assets/css/tailwind.output.css';

import { Windmill } from '@windmill/react-ui';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import ThemedSuspense from './components/ThemedSuspense';
import { SidebarProvider } from './context/SidebarContext';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './stores';
import theme from './theme';

const root = (
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<ThemedSuspense />} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </Windmill>
    </Suspense>
  </SidebarProvider>
);

ReactDOM.render(root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
