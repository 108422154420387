import { map, reject } from 'lodash';

import {
  ACTION_CREATE_NOTIFICATION,
  ACTION_DELETE_NOTIFICATION,
  ACTION_GET_NOTIFICATIONS,
  ACTION_UPDATE_NOTIFICATION,
} from './actionTypes';

const initalState = {
  notifications: {
    items: [],
    totalCount: 0,
  },
};

/**
 * @typedef NotificationRecipient
 * @property {string} id
 * @property {string} email
 */

/**
 * @typedef {object} Notification
 * @property {string} id
 * @property {number} metric
 * @property {number} operation
 * @property {number} value
 * @property {import('../topics/reducer).Keyword} keyword
 * @property {NotificationRecipient[]} notificationRecipients
 */

/**
 * Returns all notifications
 * @param {object} state
 * @returns {Notification[]}
 */
export const notificationsSelector = (state) =>
  state.notifications.notifications.items;

/**
 * Returns count of all notifications
 * @param {object} state notification
 * @returns {number}
 */
export const notificationsCountSelector = (state) =>
  state.notifications.notifications.totalCount;

/**
 * Returns all metrics
 * @param {object} state
 * @returns {Metric[]}
 */
export const metricsSelector = (state) => state.notifications.metrics;

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case ACTION_GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: payload,
      };
    }
    case ACTION_CREATE_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          items: [...state.notifications.items, payload],
          totalCount: state.notifications.totalCount + 1,
        },
      };
    }
    case ACTION_UPDATE_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          items: map(state.notifications.items, (item) => {
            if (item.id !== payload.id) {
              return item;
            }
            return payload;
          }),
          totalCount: state.notifications.totalCount,
        },
      };
    }
    case ACTION_DELETE_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          items: reject(state.notifications.items, { id: payload.id }),
          totalCount: state.notifications.totalCount - 1,
        },
      };
    }
    default:
      return state;
  }
}
