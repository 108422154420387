import bg from './bg-BG.json';
import enUS from './en-US.json';

export default {
  'en-US': {
    translation: enUS,
  },
  bg: {
    translation: bg,
  },
};
