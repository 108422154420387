import { ACTION_TOGGLE_ALERT } from './actionTypes';

/**
 * @typedef {object} Alert
 * @property {bool} visible
 * @property {string} text
 * @property {'error'|'success'} type
 *
 */

/** @type {Alert} */
const initalState = {
  visible: false,
  text: '',
  type: '',
};

/**
 * @param {object} state
 * @returns {Alert}
 */
export const alertSelector = (state) => state.alert;

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case ACTION_TOGGLE_ALERT: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}
