import { map, reject } from 'lodash';

import {
  ACTION_CREATE_ROLE,
  ACTION_DELETE_ROLE,
  ACTION_GET_ROLES,
  ACTION_UPDATE_ROLE,
} from './actionTypes';

const initalState = {
  roles: [],
};

/**
 * @typedef {object} Role
 * @property {string} id
 * @property {string} name
 */

/**
 * Returns all roles
 * @param {object} state
 * @returns {Role[]}
 */
export const rolesSelector = (state) => state.roles.roles;

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case ACTION_GET_ROLES: {
      return {
        ...state,
        roles: payload,
      };
    }
    case ACTION_CREATE_ROLE: {
      return {
        ...state,
        roles: [...state.roles, payload],
      };
    }
    case ACTION_UPDATE_ROLE: {
      return {
        ...state,
        roles: map(state.roles, (role) => {
          if (role.id !== payload.id) {
            return role;
          }
          return payload;
        }),
      };
    }
    case ACTION_DELETE_ROLE: {
      return {
        ...state,
        roles: reject(state.roles, { id: payload.id }),
      };
    }
    default:
      return state;
  }
}
