import { ACTION_TOGGLE_ALERT } from './actionTypes';

/**
 *
 * @param {import('./reducer').Alert} alert
 */
export const toggleAlert = (alert) => (dispatch) => {
  dispatch({
    type: ACTION_TOGGLE_ALERT,
    payload: alert,
  });
};
