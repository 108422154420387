import { orderBy } from 'lodash';

import { ACTION_GET_CUSTOMERS } from '../customers/actionTypes';
import {
  ACTION_GET_DATA_SOURCES,
  ACTION_GET_IMPORT_TYPES,
  ACTION_GET_LANGUAGES,
} from './actionTypes';

const initalState = {
  languages: [],
  importTypes: [],
  customers: [],
  dataSources: [],
};

/**
 * @typedef {object} Language
 * @property {string} id
 * @property {string} name
 */

/**
 * Returns all languages
 * @param {object} state
 * @returns {Language[]}
 */
export const languagesSelector = (state) => state.nomenclatures.languages;

/**
 * @typedef {object} ImportType
 * @property {string} id
 * @property {string} name
 */

/**
 * Returns all languages
 * @param {object} state
 * @returns {ImportType[]}
 */
export const importTypesSelector = (state) => state.nomenclatures.importTypes;

/**
 * @typedef DataSource
 * @property {string} id
 * @property {string} name
 */

/**
 * Returns all data sources
 * @param {object} state
 * @returns {DataSource[]}
 */
export const dataSourcesSelector = (state) => state.nomenclatures.dataSources;

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case ACTION_GET_LANGUAGES:
      return {
        ...state,
        languages: orderBy(payload, 'name'),
      };
    case ACTION_GET_IMPORT_TYPES:
      return {
        ...state,
        importTypes: payload,
      };
    case ACTION_GET_CUSTOMERS:
      return {
        ...state,
        customers: payload,
      };
    case ACTION_GET_DATA_SOURCES:
      return {
        ...state,
        dataSources: payload,
      };
    default:
      return state;
  }
}
