import cx from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from 'src/stores/alert/actions';
import { alertSelector } from 'src/stores/alert/reducer';

const ALERT_TIMEOUT = 5000;

function Alert() {
  const alert = useSelector(alertSelector);
  const dispatch = useDispatch();

  const hideAlert = useCallback(() => {
    dispatch(toggleAlert({ visible: false, text: '' }));
  }, [dispatch]);

  useEffect(() => {
    if (alert.visible) {
      setTimeout(hideAlert, ALERT_TIMEOUT);
    }
  }, [alert.visible, hideAlert]);

  return (
    <div
      className={cx(
        'absolute bottom-0 left-0 right-0 z-50 flex items-center justify-center py-4 text-center border transform origin-bottom transition-transform duration-200',
        {
          ' text-red-700 bg-red-100 border-red-400': alert.type === 'error',
          ' text-green-700 bg-green-100 border-green-400':
            alert.type === 'success',
          'scale-y-100': alert.visible,
          'scale-y-0': !alert.visible,
        }
      )}
      role="alert"
    >
      <span className="flex-1 px-4">
        <span className="max-w-sm">{alert.text}</span>
      </span>
      <span className="px-4">
        <svg
          className={cx('w-6 h-6 fill-current', {
            'text-red-500': alert.type === 'error',
            'text-green-500': alert.type === 'success',
          })}
          onClick={hideAlert}
          role="button"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
}

export default Alert;
